import axios, { AxiosRequestConfig } from 'axios';
import { useNavigate } from 'react-router-dom';
import cookiesSrvs from './cookies';

const instance = axios.create({
  baseURL: 'https://carina-retail.com/api',
  headers: {
    'Content-type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = cookiesSrvs.getTokens('access');
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const request = error.config;
    if (error.response.status === 401 && request.url === '/api/auth/refresh-token/') {
      const navigate = useNavigate();
      navigate('/login');
      return;
    }
    if (error.response.status === 401 && !request._retry) {
      request._retry = true;
      const refreshToken = cookiesSrvs.getTokens('refresh');
      return instance
        .post('/api/auth/refresh-token/', {
          refresh: refreshToken
        })
        .then((res) => {
          if (res.status === 200) {
            cookiesSrvs.setTokens(res.data);
            // set new access new token to the header
            instance.defaults.headers.common.Authorization = cookiesSrvs.getTokens('access');
            return instance(request);
          }
        })
        .catch((e) => {
          // console.log('error');
        });
    }
    return Promise.reject(error);
  }
);

export default instance;
