import { createContext, FC, useState } from 'react';
type LoaderContextType = {
  loader: boolean;
  loged: any;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setLoged: any;
  branchID: any;
  setBranchID: any;
  pendReqNum: any;
  setPendReqNum: any;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const LoaderContext = createContext<LoaderContextType>({
  loader: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoader: () => {},
  loged: { email: '', first_name: '', id: 0, last_name: '', role: '', username: '' },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoged: () => {},
  branchID: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBranchID: () => {},
  pendReqNum: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPendReqNum: () => {}
});
export const LoaderProvider: FC<any> = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const [branchID, setBranchID] = useState(localStorage.getItem('branchID'));
  const [loged, setLoged] = useState({
    email: '',
    first_name: '',
    id: 0,
    last_name: '',
    role: '',
    username: ''
  });
  const [pendReqNum, setPendReqNum] = useState(0);

  return (
    <LoaderContext.Provider
      value={{
        loader,
        setLoader,
        loged,
        setLoged,
        branchID,
        setBranchID,
        pendReqNum,
        setPendReqNum
      }}>
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
