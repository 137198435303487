import React, { FC, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { IUserCard } from '../../props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faExchangeAlt,
  faPenFancy,
  faUserAltSlash,
  faUserCheck,
  faUserClock,
  faUserTimes
} from '@fortawesome/free-solid-svg-icons';
import EditTypes from '../editTypesCards/editTypes';
import TransModalContent from '../functionsModal/transModal';
import CustomModal from '../functionsModal/CustomModal';
import ResignModalContent from '../functionsModal/resignModal';
import ChangeModalContent from '../functionsModal/changeRole';
import BlackListModalContent from '../functionsModal/blackList';
import DisclaimerModalContent from '../functionsModal/disclaimer';
import AbsenceModalContent from '../functionsModal/absence';
import './EditModal.css';

const EditModal: FC<IUserCard> = ({ isShow, setShow, usersData, selectedBranch }) => {
  const [showl, setShowl] = useState(false);
  const [title, setTitle] = useState('');
  const handleClose = () => setShow(false);
  const TYPES = [
    {
      title: 'نقل الموظف',
      color: '#4D95FF',
      icon: faExchangeAlt,
      content: <TransModalContent />
    },
    {
      title: usersData.resign_date ? 'الغاء استقالة' : 'طلب استقالة',
      color: '#CBB051',
      icon: faUserClock,
      content: <ResignModalContent />
    },
    {
      title: 'تغيير الوظيفة',
      color: '#72AC5E',
      icon: faUserCheck,
      content: <ChangeModalContent />
    },
    {
      title: 'بلاغ انقطاع',
      color: '#A62525',
      icon: faUserTimes,
      content: <AbsenceModalContent />
    },
    {
      title: 'اخلاء طرف',
      color: '#C762D8',
      icon: faPenFancy,
      content: <DisclaimerModalContent />
    },
    {
      title: 'قائمة سوداء',
      color: '#000000',
      icon: faUserAltSlash,
      content: <BlackListModalContent />
    }
  ];

  const getContent = (title: string) => {
    for (const type of TYPES) {
      if (title === type.title) {
        return type.content;
      }
    }
    return <></>;
  };

  return (
    <>
      <Modal show={isShow} centered onHide={handleClose} dir="rtl">
        <div className="p-3">
          <div className="d-flex justify-content-between">
            <h2 className="modalTitle"> قائمة التعديل </h2>
            <Button
              className="bg-transparent text-black"
              style={{
                border: '1px solid white',
                height: 'fit-content',
                boxShadow: '0px 0px 0px 4px rgba(13, 110, 253, 0.25)'
              }}
              onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </div>
          <p className="me-4">
            <span className="nameText"> {usersData?.name} </span>&nbsp;
            <span className="text-secondary"> #{usersData?.code} </span>
          </p>
          <Modal.Body>
            <Row>
              {TYPES.map((type, index) => {
                index++;
                return (
                  <Col lg="4" md="3" sm="2" key={index}>
                    <EditTypes
                      text={type}
                      setShow={setShow}
                      setShowl={setShowl}
                      usersData={usersData}
                      setTitle={setTitle}
                    />
                  </Col>
                );
              })}
            </Row>
          </Modal.Body>
        </div>
      </Modal>
      <CustomModal title={title} isShowl={showl} setShowl={setShowl} usersData={usersData}>
        {React.cloneElement(getContent(title), {
          usersData: usersData,
          setShowl: setShowl,
          selectedBranch: selectedBranch,
          title: title
        })}
      </CustomModal>
    </>
  );
};

export default EditModal;
