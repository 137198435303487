import { FC, useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Spinner, Badge, Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoaderContext from '../../context/loader';
import {
  approveRequest,
  disapproveRequest,
  getAllPendReq,
  IPendReqResponse
} from '../../Services/pendingReq';
import NoNotifications from '../../assets/images/no-notifications.svg';
import './notfCard.css';

const NotfCard: FC = () => {
  const [pendReqs, setPendReq] = useState<IPendReqResponse[]>();
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(-1);
  const { loged, setLoged } = useContext(LoaderContext);

  setLoged(localStorage.getItem('logedUser')?.split(',')[3].split(':')[1]);

  useEffect(() => {
    setLoading(true);
    getAllPendReq()
      .then((response) => {
        setPendReq(response.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const requestParser = (requestType: string) => {
    switch (requestType) {
      case 'AddRequest':
        return 'طلب إضافة';
      case 'BlacklistingRequest':
        return 'طلب قائمة سوداء';
      case 'CancelResignRequest':
        return 'طلب إلغاء إستقالة';
      case 'ChangeRoleRequest':
        return 'طلب تغيير الوظيفة';
      case 'DisclaimerRequest':
        return 'طلب اخلاء طرف';
      case 'RatingRequest':
        return 'طلب تقييم';
      case 'ResignRequest':
        return 'طلب إستقالة';
      case 'TraineeUpgradeRequest':
        return 'طلب ترقية متدرب';
      case 'TransferRequest':
        return 'طلب نقل الموظف';
      case 'AbsenceRequest':
        return 'طلب بلاغ انقطاع';
      default:
        break;
    }
  };

  const approve = (id: any) => {
    setRequesting(id);
    approveRequest(id)
      .then(() => {
        toast.success(`تم الموافقة على الطلب`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setPendReq(pendReqs?.filter((notf) => notf.id != id));
      })
      .catch((e) => {
        toast.error(`${e.response.data.detail[1]}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(-1);
      });
  };

  const disapprove = (id: any) => {
    setRequesting(id);
    disapproveRequest(id)
      .then(() => {
        toast.success(`تم رفض الطلب`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setPendReq(pendReqs?.filter((notf) => notf.id != id));
      })
      .catch((e) => {
        toast.error(`${e.response.data.detail[1]}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(-1);
      });
  };

  if (pendReqs?.length == 0)
    return (
      <>
        <div className="h-100 image-container mt-5">
          <img src={NoNotifications} className="mx-auto" width="50%" height="50%" />
        </div>
        <div className="textNoWorkers mb-5 mt-2 d-flex justify-content-center">
          لا يوجد طلبات معلقة !!
        </div>
      </>
    );

  return (
    <>
      {loading ? (
        <div className=" mt-5 d-flex justify-content-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : (
        <div className="mx-auto" style={{ width: '97%', direction: 'rtl' }}>
          <Row xs={1} md={1} className="g-3 mt-2">
            {pendReqs?.map((notf, index) => {
              index + 1;
              return (
                <>
                  <Col key={notf?.id} className="mb-1" style={{ cursor: 'pointer' }}>
                    <Accordion key={notf?.id} defaultActiveKey="0" className="notfCard">
                      <Accordion.Item eventKey={index + ''}>
                        <Accordion.Header>{requestParser(notf.type)}&nbsp;&nbsp;</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col className="col-md-9" sm={12} xs={12}>
                              {notf.notification_text}
                            </Col>
                            {!(
                              notf.type === 'BlacklistingRequest' && loged == '"Area Manager"'
                            ) && (
                              <>
                                {notf.status === 1 ? (
                                  <Col className="col-md-auto" sm={6} xs={6}>
                                    <Badge bg="success" style={{ fontSize: '13px' }}>
                                      تمت الموافقة على الطلب
                                    </Badge>
                                  </Col>
                                ) : notf.status === -1 ? (
                                  <Col className="col-md-auto" sm={6} xs={6}>
                                    <Badge bg="danger" style={{ fontSize: '13px' }}>
                                      تم رفض الطلب
                                    </Badge>
                                  </Col>
                                ) : (
                                  <>
                                    <Col className="col-md-auto" sm={6} xs={6}>
                                      {loged == '"Operations Manager"' ||
                                      loged == '"Area Manager"' ? (
                                        <button
                                          className="btn agreeBtn m-3"
                                          id="delete"
                                          onClick={() => approve(notf.id)}
                                          disabled={notf.id == requesting}>
                                          {notf.id == requesting ? (
                                            <div className="d-flex justify-content-center">
                                              <Spinner animation="border" variant="danger" />
                                            </div>
                                          ) : (
                                            <span className="ms-2">موافق</span>
                                          )}
                                        </button>
                                      ) : (
                                        <Badge bg="warning" style={{ fontSize: '13px' }}>
                                          يحتاج الى موافقة او رفض
                                        </Badge>
                                      )}
                                    </Col>
                                    <Col className="col-md-auto" sm={6} xs={6}>
                                      {(loged == '"Operations Manager"' ||
                                        loged == '"Area Manager"') && (
                                        <button
                                          className="btn disagreeBtn m-3"
                                          id="delete"
                                          onClick={() => disapprove(notf.id)}
                                          disabled={notf.id == requesting}>
                                          {notf.id == requesting ? (
                                            <div className="d-flex justify-content-center">
                                              <Spinner animation="border" variant="danger" />
                                            </div>
                                          ) : (
                                            <span className="ms-2">غير موافق</span>
                                          )}
                                        </button>
                                      )}
                                    </Col>
                                  </>
                                )}
                              </>
                            )}
                          </Row>
                          {notf.comment && (
                            <Col>
                              <b>التعليق :</b> {notf.comment}
                            </Col>
                          )}
                          {notf.created_at && (
                            <Col className={'text-muted'}>
                              {new Date(notf.created_at).toLocaleString()}
                            </Col>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      )}
    </>
  );
};

export default NotfCard;
