// import axios from './axiosInstance';
import axios from './axiosInstance';

export interface IUserLogin {
  username: string;
  password: string;
}
export interface mockAxios {
  data: IUserLogin;
}

export const login = (data: IUserLogin) => {
  return axios.post<any>('api/auth/login/', data);
};

export const logedUser = () => {
  return axios.get<any>('api/manager/me/');
};

export interface mockAxios3 {
  data: boolean;
}

export const canAdd = () => {
  return axios.get<any>('api/value/can-managers-add-worker/');
};
