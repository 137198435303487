import axios from './axiosInstance';
import { IRole } from '../Model/IRole';

export interface mockAxios {
  data: IRole[];
}

export const getAllRoles = async (id: any | undefined): Promise<IRole[]> => {
  const data = await axios.get<Array<any>>(`/api/role/branch/${id}/`);
  const roles = data.data.map((roleData) => {
    const role: IRole = {
      id: roleData.id,
      title: roleData.name,
      traineeNum: 0,
      capacity: 0,
      number: 0
    };
    return role;
  });
  return roles;
};
export const getAllRolesDic = async (): Promise<any[]> => {
  const data = await axios.get<Array<any>>(`/api/role/me/`);
  let roles: any = {};
  data.data.map((roleData) => {
    roles = { ...roles, [roleData.id]: roleData.name };
  });
  return roles;
};
export const getRole = async (roleId: number | undefined, branchId: string | undefined) => {
  const roles = await getAllRoles(branchId);
  for (const role of roles) {
    if (role.id == roleId) return role.title;
  }
};
