import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/style.css';
import AccDash from './pages/accountDashboard/accDash';
import RatingPage from './pages/RatingPage/RatingPage';
import Login from './pages/login/Login';
import NotfCard from './components/notfCard/notfCard';
import Layout from './Layout';
import IsAuth from './IsAuth';
import { LoaderProvider } from './context/loader';

function App() {
  return (
    <div style={{ direction: 'rtl' }}>
      <BrowserRouter>
        <LoaderProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<IsAuth />}>
              <Route path="/" element={<Layout />}>
                <Route path="/rating/:id" element={<RatingPage />} />
                <Route path="/notif" element={<NotfCard />} />
                <Route path="/dashboard" element={<AccDash />} />
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
              </Route>
            </Route>
          </Routes>
        </LoaderProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
