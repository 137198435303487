import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { cancelResign, resignWorkerReq } from '../../Services/worker';
import { IModal } from '../../props';
import './funcModal.css';

const ResignModalContent: FC<IModal> = ({ title, setShowl, usersData }) => {
  const INITIAL_RESIGN_STATE = {
    worker: '',
    resign_date: '',
    branch: usersData?.branch,
    comment: '',
    reason: ''
  };

  const [resign, setResign] = useState(INITIAL_RESIGN_STATE);
  const [requesting, setRequesting] = useState(false);
  const [cancelresign, setcancelResign] = useState({
    worker: '',
    branch: usersData?.branch,
    comment: ''
  });
  const [validate, setValidate] = useState(usersData?.resign_date ? false : true);

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (value === 'اخرى') {
      setResign((values) => ({ ...values, [name]: value }));
      resign.comment = '';
    } else setResign((values) => ({ ...values, [name]: value }));
    if (resign.resign_date) {
      setValidate(false);
    }
  };

  const resignWorker = () => {
    setRequesting(true);
    const data = {
      worker: usersData?.id,
      comment: resign.comment || resign.reason,
      resign_date: resign.resign_date,
      branch: resign.branch,
      reason: resign.reason
    };
    resignWorkerReq(data)
      .then((response) => {
        setResign({
          worker: `${usersData?.id}`,
          comment: response.data.comment,
          resign_date: response.data.resign_date,
          branch: response.data.branch,
          reason: response.data.reason
        });
        toast.success(`تم طلب ${title} ل${usersData?.name}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e: Error) => {
        toast.error(`لقد فشل الطلب `, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const cancelResignWorker = () => {
    setRequesting(true);
    const data = {
      worker: usersData?.id,
      comment: cancelresign.comment,
      branch: cancelresign.branch
    };
    cancelResign(data)
      .then((response) => {
        setcancelResign({
          worker: `${usersData?.id}`,
          comment: response.data.comment,
          branch: response.data.branch
        });
        toast.success(`تم الغاء طلب الاستقالة ل${usersData?.name}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e: any) => {
        toast.error(`${e.response.data.detail[1]}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const onClick = () => {
    if (usersData?.resign_date) {
      cancelResignWorker();
    } else {
      resignWorker();
    }
    setShowl(false);
  };

  return (
    <>
      <div className="mt-3 me-2">
        {usersData?.resign_date === null && (
          <div className="d-flex">
            <div style={{ width: '48%' }}>
              <Form.Group controlId="dob">
                <Form.Control
                  name="resign_date"
                  value={resign.resign_date}
                  onChange={handleInputChange}
                  type="date"
                  placeholder="تاريخ الاستقالة"
                />
              </Form.Group>
            </div>
            <div style={{ width: '45%' }}>
              <Form.Group controlId="dob">
                <Form.Select
                  className="me-3"
                  name="reason"
                  value={resign.reason}
                  onChange={handleInputChange}>
                  <option disabled={!!resign.reason}>اختر سبب</option>
                  <option>مشاكل صحية</option>
                  <option>عرض افضل</option>
                  <option>المدير</option>
                  <option>العائد المادى</option>
                  <option>معاملة الزملاء</option>
                  <option>اداريات</option>
                  <option>التوزيع الجغرافى</option>
                  <option>عدد ساعات العمل</option>
                  <option>ظروف شخصية</option>
                  <option>اخرى</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        )}
        {resign.reason === 'اخرى' && (
          <div className="mt-2 me-2" style={{ width: '97%' }}>
            <FloatingLabel controlId="floatingTextarea2" label="سبب الاستقالة">
              <Form.Control
                name="comment"
                value={resign.comment}
                onChange={handleInputChange}
                as="textarea"
                style={{ height: '100px' }}
              />
            </FloatingLabel>
          </div>
        )}
      </div>
      <Button
        disabled={validate || requesting}
        className="float-start mt-3"
        style={{ width: '20%' }}
        onClick={onClick}>
        {requesting ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <span>تأكيد</span>
        )}
      </Button>
    </>
  );
};

export default ResignModalContent;
