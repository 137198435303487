import { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IModal } from '../../props';
import './funcModal.css';

const CustomModal: FC<IModal> = ({ title, isShowl, setShowl, usersData, children }) => {
  return (
    <Modal show={isShowl} centered onHide={() => setShowl(false)} dir="rtl">
      <div className="p-3 me-2">
        <p className="titleTextF">{title}</p>
        <div className="textUserData me-2">
          <span>{usersData?.name}</span>&nbsp;&nbsp;&nbsp;
          <span className="text-secondary">#{usersData?.code}</span>
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
