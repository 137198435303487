import { FC } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { IAccDash, IStatCardImage } from '../../props';
import IconPlaceholder from '../../assets/images/user.png';
import SandGlass from '../../assets/images/sandglass.jpg';
import './statCard.css';

const Image: FC<IStatCardImage> = ({ url }) => {
  return (
    <img
      height="70"
      src={url || IconPlaceholder}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = IconPlaceholder;
      }}
    />
  );
};

const CustCard: FC<IAccDash> = ({ stat, title, number }) => {
  return (
    <>
      {title === 'طلبات معلقة' ? (
        <>
          <Row
            style={{ width: '99%' }}
            className="cardDiv p-2 h-100 align-items-baseline justify-content-between bg-white">
            <p className="titleText"> {title} </p>
            <Col md="5" sm="6" xs={6}>
              <p className="capacityText ignore">{number}</p>
            </Col>
            <Col md="3" sm="6" xs={6}>
              <img src={SandGlass} height="70" />
            </Col>
          </Row>
        </>
      ) : (
        <div className="cardDiv p-2 h-100 align-items-baseline bg-white">
          <p className="titleText"> {stat?.role_name} </p>
          <Row className="align-items-baseline">
            <Col md="4" xs={6} sm={6}>
              <p className="capacityText">
                {stat?.capacity} / {stat?.actual_number}
              </p>
            </Col>
            <Col md="3" xs={6} sm={6} className="d-md-none d-inline pb-2">
              <Image url={`http://carina-retail.com/${stat?.role_image}` || ''} />
            </Col>
            <Col md="5">
              <div>
                {stat?.capacity === stat?.actual_number ? (
                  <ProgressBar
                    striped
                    variant="progVariant"
                    max={stat?.capacity}
                    now={stat?.actual_number}
                  />
                ) : (
                  <ProgressBar striped animated max={stat?.capacity} now={stat?.actual_number} />
                )}
              </div>
            </Col>
            <Col md="3" className="d-md-inline d-none">
              <Image url={stat?.role_image || ''} />
            </Col>
          </Row>
          <Row>
            <Col className="col-auto">{stat?.trainees_number} متدربين</Col>
            <Col className="col-auto">{stat?.female_number} إناث</Col>
            <Col className="col-auto">{stat?.male_number} ذكور</Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default CustCard;
