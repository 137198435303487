import { Outlet, useLocation, Navigate } from 'react-router-dom';

const IsAuth = () => {
  const location = useLocation();
  return localStorage.getItem('user') ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default IsAuth;
