import axios from './axiosInstance';
import { IRatingQ, IRatingA } from '../Model/IRatingQA';
import { Q, A } from './api';

export interface mockAxios {
  dataQ: IRatingQ[];
  dataA: IRatingA[];
}

export const getQs = () => {
  return axios.get<Array<IRatingQ>>('/api/question/all/');
};

export interface IRateResponse {
  worker: number;
  questions: number[];
  answers: number[];
  id: number;
  branch: number;
  manager_name: string;
  comment: string;
  type: string;
}

export const setAs = (data: any) => {
  return axios.post<Array<IRateResponse>>('/api/request/create/rate/', data);
};

export const getAs = () => {
  const moAxios: mockAxios = {
    dataA: A,
    dataQ: []
  };
  const myPromise = new Promise<mockAxios>((resolve, reject) => {
    setTimeout(() => {
      resolve(moAxios);
    }, 300);
  });
  return myPromise;
};
