import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { IRatingQ } from '../../Model/IRatingQA';
import { getQs } from '../../Services/QA';
import { QAProps } from '../../props';
import './QA.css';

const QA: FC<QAProps> = ({ userRole, Qus, Ans }) => {
  const INITIAL_QA_DATA = {
    worker: userRole?.id,
    branch: userRole?.branch,
    questions: [],
    answers: [],
    comment: ''
  };

  const [As, setAs] = useState(INITIAL_QA_DATA);
  const [Qs, setQs] = useState<IRatingQ[]>([]);
  const [requesting, setRequesting] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<any>) => {
    const { name, value } = event.target;
    Ans((values: any) => ({ ...values, [name]: value }));
    setAs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    setRequesting(true);
    getQs()
      .then((response) => {
        const qID = response.data.filter((question) => question.roles.includes(userRole?.role));
        const arrID: number[] = [];
        qID.forEach((qId) => {
          arrID.push(qId.id);
          Qus(arrID);
        });
        setQs(response.data.filter((question) => question.roles.includes(userRole?.role)));
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {
        setRequesting(false);
      });
  }, [userRole?.role]);

  if (requesting)
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="danger" />
      </div>
    );

  return (
    <>
      <Container>
        {Qs.map((q, index: number) => {
          return (
            <React.Fragment key={index}>
              <Row key={q.id}>
                <span className="questionText">
                  {index + 1}- {q.question}
                </span>
              </Row>
              <Row>
                {[1, 2, 3, 4, 5].map((a) => {
                  return (
                    <Col
                      key={a}
                      // style={{ width: '15%' }}
                      md={1}
                      sm={2}
                      xs={2}
                      className="me-md-5"
                      dir="ltr">
                      <Form.Check
                        type="radio"
                        value={a}
                        onChange={handleInputChange}
                        name={`${index}`}
                        id={a + ''}
                      />
                      <FormCheckLabel className="questionText text-center">{a}</FormCheckLabel>
                    </Col>
                  );
                })}
              </Row>
            </React.Fragment>
          );
        })}
      </Container>
    </>
  );
};

export default QA;
