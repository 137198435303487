import { Outlet } from 'react-router-dom';
import CustomNav from './layout/Nav/Nav';

const Layout = () => {
  return (
    <main>
      <CustomNav />
      <Outlet />
    </main>
  );
};

export default Layout;
