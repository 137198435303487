import { FC } from 'react';
import { IAccDash } from '../../props';
import { calculateStars } from './star.utils';

const Star: FC<IAccDash> = ({ branchRate = 0 }) => {
  const stars = calculateStars(branchRate);
  return (
    <>
      <div className="">
        {stars.map((star, index) => {
          index++;
          return <span key={index}>{star}</span>;
        })}
      </div>
    </>
  );
};

export default Star;
