import { IBranch } from '../Model/IBranch';
import { IUser } from '../Model/user';

// export const obj: IUser[] = [
//   {
//     id: '1',
//     name: 'زهرة محمد',
//     img: require('../assets/images/user.png'),
//     role: 'مدير مبيعات',
//     Rating: 4,
//     phoneNumber: '0100000000',
//     email: 'alzahra@gmail.com',
//     internalCode: '1123',
//     nationalID: 23090247877,
//     gender: 'Female',
//     birthDate: '5/8/1999',
//     hiringDate: '9/5/2022'
//   },
//   {
//     id: '2',
//     name: 'سارة سامر',
//     img: require('../assets/images/user.png'),
//     role: 'مدير مبيعات',
//     Rating: 3,
//     phoneNumber: '0100000000',
//     email: 'alzahra@gmail.com',
//     internalCode: '1223',
//     nationalID: 23090247877,
//     gender: 'Female',
//     birthDate: '5/8/1999',
//     hiringDate: '9/5/2022'
//   },
//   {
//     id: '3',
//     name: 'احمد وسام',
//     img: require('../assets/images/user.png'),
//     role: 'مدير مبيعات',
//     Rating: 5,
//     phoneNumber: '0100000000',
//     email: 'alzahra@gmail.com',
//     internalCode: '1323',
//     nationalID: 23090247877,
//     gender: 'Female',
//     birthDate: '5/8/1999',
//     hiringDate: '9/5/2022'
//   },
//   {
//     id: '4',
//     name: 'احمد ناصر',
//     img: require('../assets/images/user.png'),
//     role: 'مدير مبيعات',
//     Rating: 4,
//     phoneNumber: '0100000000',
//     email: 'alzahra@gmail.com',
//     internalCode: '1423',
//     nationalID: 23090247877,
//     gender: 'Female',
//     birthDate: '5/8/1999',
//     hiringDate: '9/5/2022'
//   },
//   {
//     id: '5',
//     name: 'سارة سامر',
//     img: require('../assets/images/user.png'),
//     role: 'مدير مبيعات',
//     Rating: 1,
//     phoneNumber: '0100000000',
//     email: 'alzahra@gmail.com',
//     internalCode: '1523',
//     nationalID: 23090247877,
//     gender: 'Female',
//     birthDate: '5/8/1999',
//     hiringDate: '9/5/2022'
//   },
//   {
//     id: '6',
//     name: 'زهرة محمد',
//     img: require('../assets/images/user.png'),
//     role: 'مدير مبيعات',
//     Rating: 5,
//     phoneNumber: '0100000000',
//     email: 'alzahra@gmail.com',
//     internalCode: '1623',
//     nationalID: 23090247877,
//     gender: 'Female',
//     birthDate: '5/8/1999',
//     hiringDate: '9/5/2022'
//   }
// ];

export const roles = [
  {
    title: 'مديرين',
    capacity: 1,
    number: 1,
    traineeNum: 2
  },
  {
    title: 'مساعدين',
    capacity: 2,
    number: 1,
    traineeNum: 2
  },
  {
    title: 'مبيعات',
    capacity: 6,
    number: 4,
    traineeNum: 2
  }
];

// export const Branches: IBranch[] = [
//   {
//     id: '1',
//     name: 'فرع المقطم',
//     address: '١٢ شارع ٩ - المقطم',
//     code: 111,
//     rating: 5
//   },
//   {
//     id: '2',
//     name: 'فرع الهرم',
//     address: 'التعاون, الهرم',
//     code: 222,
//     rating: 3
//   },
//   {
//     id: '3',
//     name: 'فرع المعادى',
//     address: 'شارع 9',
//     code: 333,
//     rating: 4
//   }
// ];

export const Q = [
  { id: '1', question: 'هل يأتي الموظف في مواعيد العمل؟' },
  { id: '2', question: 'هل يأتي الموظف في مواعيد العمل؟' },
  { id: '3', question: 'هل يأتي الموظف في مواعيد العمل؟' }
];

export const A = [
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' }
];

export const notfications = [
  {
    id: 1,
    usrName: 'زهرة محمد',
    img: require('../assets/images/user.png'),
    msg: 'يريد زهرة محمد حذف سارة سامر (0010#) فرع المقطم'
  },
  {
    id: 2,
    usrName: 'زهرة محمد',
    img: require('../assets/images/user.png'),
    msg: 'يريد زهرة محمد حذف سارة سامر (0010#) فرع المقطم'
  },
  {
    id: 3,
    usrName: 'زهرة محمد',
    img: require('../assets/images/user.png'),
    msg: 'يريد زهرة محمد حذف سارة سامر (0010#) فرع المقطم'
  },
  {
    id: 4,
    usrName: 'زهرة محمد',
    img: require('../assets/images/user.png'),
    msg: 'يريد زهرة محمد حذف سارة سامر (0010#) فرع المقطم'
  },
  {
    id: 5,
    usrName: 'زهرة محمد',
    img: require('../assets/images/user.png'),
    msg: 'يريد زهرة محمد حذف سارة سامر (0010#) فرع المقطم'
  },
  {
    id: 6,
    usrName: 'زهرة محمد',
    img: require('../assets/images/user.png'),
    msg: 'يريد زهرة محمد حذف سارة سامر (0010#) فرع المقطم'
  }
];

export const locations = [
  {
    id: 0,
    name: 'الهرم'
  },
  {
    id: 1,
    name: 'المعادى'
  },
  {
    id: 2,
    name: 'الزمالك'
  },
  {
    id: 3,
    name: 'المقطم'
  }
];
