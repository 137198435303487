import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { INITIAL_WORKER_STATE, NAMES } from './addModal.constants';
import { IBranch } from '../../Model/IBranch';
import { IRole } from '../../Model/IRole';
import { IUserAddition } from '../../Model/user';
import { ILocation } from '../../Model/ILocation';
import { IValidate } from '../../Model/Ivalidation';
import { IModal } from '../../props';
import { getBranchByManager } from '../../Services/branch';
import { getAllRoles } from '../../Services/role';
import { addWorker, getLocation } from '../../Services/worker';
import placeholder from '../../assets/images/carina-user.png';
import './addModal.css';

const AddModal: FC<IModal> = ({ showAdd, setShowAdd }) => {
  const [allBranches, setAllBranches] = useState<IBranch[]>([]);
  const [allRoles, setAllRoles] = useState<IRole[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [newWorker, setNewWorker] = useState(INITIAL_WORKER_STATE);
  const [imageSrc, setImgSrc] = useState('');
  const [validate, setValidate] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [errors, setErrors] = useState<any>();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<any>) => {
    const { name, value, files } = event.target;
    if (files) {
      setImgSrc(URL.createObjectURL(files[0]));
      const file = files[0];
      getBase64(file);
    }
    const key = name as keyof IValidate;
    const tempWorker = { ...newWorker, [name]: value };
    if (name !== 'comment' && name !== 'image') {
      const tempErrors = {
        ...errors,
        [key]: NAMES[key].validate(event.target.value) ? (
          <small className="text-danger">{NAMES[key].msg}</small>
        ) : null
      };
      setErrors(tempErrors);
      setNewWorker(tempWorker);
    } else {
      setNewWorker(tempWorker);
    }

    const isValid = Object.keys(NAMES).reduce((prevBool, k) => {
      const k1 = k as keyof IValidate;
      const k2 = k as keyof typeof tempWorker;
      return prevBool || NAMES[k1].validate(tempWorker[k2].toString());
    }, false);

    setValidate(isValid);
  };

  const onLoad = (fileString: any) => {
    const tempWorker = { ...newWorker, image: fileString };
    setNewWorker(tempWorker);
  };

  const getBase64 = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };

  const addNewWorker = () => {
    setRequesting(true);
    const data: IUserAddition = {
      first_name: newWorker.first_name,
      second_name: newWorker.second_name,
      third_name: newWorker.third_name,
      fourth_name: newWorker.fourth_name,
      code: newWorker.code,
      national_id: newWorker.national_id,
      phone_number: `+2${newWorker.phone_number}`,
      gender: newWorker.gender,
      birth_date: newWorker.birth_date,
      hiring_date: newWorker.hiring_date,
      is_trainee: true,
      role: +newWorker.role,
      branch: +newWorker.branch,
      is_graduated: newWorker.is_graduated == 'طالب' ? false : true,
      comment: newWorker.comment,
      location: +newWorker.location
    };
    if (newWorker.image) data.image = newWorker.image;
    addWorker(data)
      .then((response) => {
        toast.success(`تم طلب اضافة ${newWorker.first_name}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setShowAdd(false);
      })
      .catch((e: any) => {
        toast.error(`${e.response.data.detail[1]}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };
  useEffect(() => {
    getBranchByManager()
      .then((response) => {
        setAllBranches(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);
  useEffect(() => {
    newWorker.branch &&
      getAllRoles(newWorker.branch + '')
        .then((response) => {
          setAllRoles(response);
        })
        .catch((error) => {
          setAllRoles([]);
        });
  }, [newWorker.branch]);
  useEffect(() => {
    getLocation()
      .then((response) => {
        setLocations(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      <Modal show={showAdd} centered onHide={() => setShowAdd(false)} dir="rtl">
        <div className="p-3">
          <p className="textTitleAdd mb-2"> اضافة موظف </p>
          <Form>
            <div className="d-flex flex-column">
              <Row className="mb-3">
                <Col md={12} sm={12} xs={12}>
                  <div className="d-flex justify-content-center mb-4">
                    <img
                      src={imageSrc || placeholder}
                      width={100}
                      className="rounded-5 imageDivUser"
                    />
                    <label htmlFor="file-upload">
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="position-absolute plusIconImg"
                      />
                    </label>
                    <input id="file-upload" name="image" type="file" onChange={handleInputChange} />
                  </div>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>الاسم الاول</Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      value={newWorker.first_name || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <div>{errors?.first_name}</div>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>الاسم الثانى</Form.Label>
                    <Form.Control
                      type="text"
                      name="second_name"
                      value={newWorker.second_name || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <div>{errors?.second_name}</div>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>الاسم الثالث</Form.Label>
                    <Form.Control
                      type="text"
                      name="third_name"
                      value={newWorker.third_name || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <div>{errors?.third_name}</div>
                </Col>
                <Col md={3} sm={6} xs={6}>
                  <Form.Group>
                    <Form.Label>الاسم الرابع</Form.Label>
                    <Form.Control
                      type="text"
                      name="fourth_name"
                      value={newWorker.fourth_name || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <div>{errors?.fourth_name}</div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="col-6">
                  <Form.Group>
                    <Form.Label>الرقم القومى</Form.Label>
                    <Form.Control
                      type="number"
                      name="national_id"
                      value={newWorker.national_id || ''}
                      onChange={handleInputChange}
                      min="0"
                      required
                    />
                  </Form.Group>
                  <div>{errors?.national_id}</div>
                </Col>
                <Col className="col-6">
                  <Form.Group>
                    <Form.Label>رقم الهاتف</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="phone_number"
                        value={newWorker.phone_number || ''}
                        onChange={handleInputChange}
                        min="0"
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                  <div>{errors?.phone_number}</div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Form.Label>الدرجة العلمية</Form.Label>
                  <Form.Select
                    name="is_graduated"
                    value={newWorker.is_graduated}
                    onChange={handleInputChange}
                    aria-label="degress">
                    <option value="" disabled={!!newWorker.is_graduated}>
                      اختر درجة علمية
                    </option>
                    <option value="طالب">طالب</option>
                    <option value="خريج">خريج</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>مكان السكن</Form.Label>
                  <Form.Select
                    name="location"
                    value={newWorker.location}
                    onChange={handleInputChange}
                    aria-label="location">
                    <option disabled={!!newWorker.location}>اختر مكان السكن</option>
                    {locations.map((loc) => {
                      return (
                        <option value={loc.id} key={loc.id}>
                          {loc.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="col-6">
                  <Form.Group className="">
                    <Form.Label>كود الموظف</Form.Label>
                    <Form.Control
                      type="text"
                      name="code"
                      value={newWorker.code || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <div>{errors?.code}</div>
                </Col>
                <Col className="col-6">
                  <Form.Group className="mt-2 me-4">
                    <Form.Label>النوع</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        value="M"
                        className="ms-3"
                        onChange={handleInputChange}
                        type="radio"
                        label="ذكر"
                        name="gender"
                        id="male"
                      />
                      <Form.Check
                        value="F"
                        onChange={handleInputChange}
                        type="radio"
                        label="انثى"
                        name="gender"
                        id="female"
                      />
                    </div>
                  </Form.Group>
                  <div>{errors?.gender}</div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="col-6">
                  <Form.Group>
                    <Form.Label>تاريخ الميلاد</Form.Label>
                    <Form.Control
                      type="date"
                      name="birth_date"
                      value={newWorker.birth_date || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col className="col-6">
                  <Form.Group>
                    <Form.Label>تاريخ التوظيف</Form.Label>
                    <Form.Control
                      type="date"
                      name="hiring_date"
                      value={newWorker.hiring_date || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="col-6">
                  <Form.Select
                    name="branch"
                    value={newWorker.branch}
                    onChange={handleInputChange}
                    aria-label="branches">
                    <option disabled={!!newWorker.branch}>اختر فرع</option>
                    {allBranches.map((branch) => {
                      return (
                        <option value={branch.id} key={branch.id}>
                          {branch.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col className="col-6">
                  <Form.Select
                    disabled={!newWorker.branch}
                    name="role"
                    value={newWorker.role}
                    onChange={handleInputChange}
                    aria-label="roles">
                    {allRoles.length ? (
                      <option disabled={!!newWorker.role}>اختر وظيفة</option>
                    ) : (
                      <option disabled>لا يوجد وظائف فى هذا الفرع</option>
                    )}
                    {allRoles.map((role) => {
                      return (
                        <option value={role.id} key={role.id}>
                          {role.title}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FloatingLabel dir="rtl" controlId="floatingTextarea2" label="تعليق على الاضافة">
                    <Form.Control
                      name="comment"
                      value={newWorker.comment}
                      onChange={handleInputChange}
                      as="textarea"
                      style={{ height: '100px' }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <Button
              className="float-start mt-3 btnAdd"
              style={{ width: '20%' }}
              disabled={requesting || validate}
              onClick={addNewWorker}>
              {requesting ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="danger" />
                </div>
              ) : (
                <span>تأكيد</span>
              )}
            </Button>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddModal;
