import { IBranch } from '../Model/IBranch';
import axios from './axiosInstance';

export interface mockAxios {
  data: IBranch[];
}

// all branches in add and transform
export const getAll = () => {
  return axios.get<Array<IBranch>>('/api/branch/all/');
};

// Return branches per manager (the current logged one)
export const getBranchByManager = () => {
  return axios.get<Array<IBranch>>('/api/branch/me/');
};

export const getBranchById = (id: any) => {
  return axios.get<IBranch>(`/api/branch/${id}/`);
};
