import { ChangeEvent, FC, useState } from 'react';
import { Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IModal } from '../../props';
import { Disclaiming } from '../../Services/worker';
import './funcModal.css';

const DisclaimerModalContent: FC<IModal> = ({ selectedBranch, title, setShowl, usersData }) => {
  const INITIAL_DISCLAIMER_STATE = {
    worker: '',
    branch: usersData?.branch,
    comment: ''
  };

  const [disclaimer, setDisclaimer] = useState(INITIAL_DISCLAIMER_STATE);
  const [requesting, setRequesting] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<any>) => {
    const { name, value } = event.target;
    setDisclaimer((values) => ({ ...values, [name]: value }));
  };

  const DisclaimWorker = () => {
    setRequesting(true);
    const data = {
      worker: usersData?.id,
      branch: disclaimer.branch,
      comment: disclaimer.comment
    };
    Disclaiming(data)
      .then((response) => {
        setDisclaimer({
          worker: `${usersData?.id}`,
          branch: response.data.branch,
          comment: response.data.comment
        });
        toast.success(`تم طلب ${title} ل${usersData?.name}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e: any) => {
        toast.error(`${e.response.data.detail[1]}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const onClick = () => {
    DisclaimWorker();
    setShowl(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-3 me-2">
        <div style={{ width: '90%' }}>
          <FloatingLabel controlId="floatingTextarea2" label="تعليق">
            <Form.Control
              name="comment"
              value={disclaimer.comment}
              onChange={handleInputChange}
              as="textarea"
              style={{ height: '100px' }}
            />
          </FloatingLabel>
        </div>
      </div>
      <Button
        disabled={requesting}
        className="float-start mt-3"
        style={{ width: '20%' }}
        onClick={onClick}>
        {requesting ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <span>تأكيد</span>
        )}
      </Button>
    </>
  );
};

export default DisclaimerModalContent;
