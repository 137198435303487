import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import LoaderContext from '../../context/loader';
import { IBranch } from '../../Model/IBranch';
import { getBranchByManager } from '../../Services/branch';
import { IAccDash } from '../../props';
import './filter.css';

const FilterList: FC<IAccDash> = ({ setBranch, stat }) => {
  const SELECTED_BRANCH_ID = 'selectedBranch';
  const ALL_BRANCHES: IBranch = {
    id: 'All',
    name: 'كل الفروع',
    address: '',
    code: 0,
    rating: 0,
    total_capacity: stat[0]?.sum_capacity,
    total_actual_capacity: stat[0]?.sum_actual
  };

  const [branches, setBranches] = useState<IBranch[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState('');
  const { loged } = useContext(LoaderContext);
  const { setBranchID } = useContext(LoaderContext);

  useEffect(() => {
    const id = localStorage.getItem(SELECTED_BRANCH_ID);
    getBranchByManager()
      .then((response) => {
        const branchesWithAll = [ALL_BRANCHES, ...response.data];
        const branch = branchesWithAll.filter((b) => b.id == id);
        setBranches(branchesWithAll);

        if (branch.length == 0) {
          if (loged === '"Operations Manager"' || loged === '"Area Manager"') {
            setBranch(branchesWithAll[0]);
            setBranchID(branchesWithAll[0].id);
            setSelectedBranchId(branchesWithAll[0].id);
          } else {
            setBranch(response.data[0]);
            setBranchID(response.data[0].id);
            setSelectedBranchId(response.data[0].id);
          }
        } else {
          setBranch(branch[0]);
          setBranchID(branch[0].id);
          setSelectedBranchId(branch[0].id);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [stat[0]?.sum_capacity, stat[0]?.sum_actual]);

  const onSelect = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<any>) => {
    const selectedId = e.target.value;
    const branch = branches.filter((b) => b.id == selectedId)[0];
    setBranchID(branch.id);
    setBranch(branch);
    setSelectedBranchId(branch.id);
    localStorage.setItem(SELECTED_BRANCH_ID, branch.id);
  };

  return (
    <>
      {(loged === '"Operations Manager"' || loged === '"Area Manager"') && (
        <div>
          <Form.Select id="filterSelect" defaultValue="All" onChange={onSelect}>
            {branches.map((branch, index) => {
              return (
                <option
                  style={{ backgroundColor: 'white', color: 'gray' }}
                  value={branch.id}
                  key={index}
                  selected={selectedBranchId === branch.id}>
                  {branch.name}
                  {!isNaN(branch?.total_actual_capacity - branch?.total_capacity) && (
                    <> ({branch?.total_actual_capacity - branch?.total_capacity}) </>
                  )}
                </option>
              );
            })}
          </Form.Select>
        </div>
      )}
    </>
  );
};

export default FilterList;
