import axios from './axiosInstance';
import { IStatResponse } from '../Model/IStatResponse';
import { IRole } from '../Model/IRole';

export interface mockAxios {
  data: IRole[];
}

const requestFactory = (stat: IStatResponse) => {
  stat.actual_number = stat.female_number + stat.male_number;
  stat.capacity = stat.female_capacity + stat.male_capacity;
  return stat;
};

export const getAllStats = async (id: any) => {
  const stats = (await axios.get<IStatResponse[]>(`/api/statistics/branch/${id}/`)).data;
  stats.map((stat) => requestFactory(stat));
  return stats;
};

export const getAllStatsByManager = async () => {
  const stats = (await axios.get<IStatResponse[]>('/api/statistics/me/')).data;
  stats.map((stat) => requestFactory(stat));
  stats[0].sum_actual = stats.reduce((accumelator, current) => {
    return accumelator + current.actual_number;
  }, 0);
  stats[0].sum_capacity = stats.reduce((accumelator, current) => {
    return accumelator + current.capacity;
  }, 0);
  return stats;
};
