// import axios from './axiosInstance';
import axios from './axiosInstance';
import { IUser } from '../Model/user';
import { ILocation } from '../Model/ILocation';
import { locations } from './api';
// import { obj } from './api';

export interface mockAxios {
  data: IUser[];
}

export interface mockAxios2 {
  data: IUser;
}

export interface request {
  branch: any;
  id?: any;
  new_branch: string;
  resign_date?: any;
  new_role: any;
  comment: string;
  internal_code: any;
  reason: any;
}

const workerFactory = (worker: IUser) => {
  worker.name =
    worker.first_name +
    ' ' +
    worker.second_name +
    ' ' +
    worker.third_name +
    ' ' +
    worker.fourth_name;
  worker.gender = worker.gender == 'M' ? 'Male' : 'Female';
  return worker;
};

export const getAll = async (id: string | undefined): Promise<IUser[]> => {
  const data = await axios.get<Array<any>>(`api/worker/branch/${id}/`);
  const workers: Array<any> = data.data.map(workerFactory);
  return workers;
};

export const addWorker = (newData: any) => {
  return axios.post<IUser>('api/request/create/add/', newData);
};

export const transferWorker = (data: any) => {
  return axios.post<request>('api/request/create/transfer/', data);
};

export const confirmTransWorker = (id: any) => {
  return axios.put<any>(`api/worker/${id}/receive/`);
};

export const resignWorkerReq = (data: any) => {
  return axios.post<request>('/api/request/create/resign/', data);
};

export const cancelResign = (data: any) => {
  return axios.post<request>('/api/request/create/cancel-resign/', data);
};

export const changeWorkerRole = (data: any) => {
  return axios.post<request>('/api/request/create/change-role/', data);
};

export const traineeUpgradeRequest = (data: any) => {
  return axios.post<request>('/api/request/create/trainee-upgrade/', data);
};

export const BlackListed = (data: any) => {
  return axios.post<request>('/api/request/create/blacklisting/', data);
};

export const Disclaiming = (data: any) => {
  return axios.post<request>('/api/request/create/disclaimer/', data);
};

export const Absence = (data: any) => {
  return axios.post<request>('/api/request/create/absence/', data);
};

export const getWorkerByID = (id: string) => {
  return axios.get<IUser>(`/api/worker/${id}/`);
};

export const getWorkerByManager = async (): Promise<IUser[]> => {
  const data = await axios.get<Array<any>>('/api/worker/me/');
  const workers: Array<any> = data.data.map(workerFactory);
  return workers;
};

export interface mockAxios3 {
  locData: ILocation[];
}

export const getLocation = () => {
  // const mocking: mockAxios3 = { locData: locations };
  // const myPromise = new Promise<mockAxios3>((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve(mocking);
  //   }, 300);
  // });
  // return myPromise;
  return axios.get<ILocation[]>(`/api/location/all/`);
};
