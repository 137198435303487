import { FC, useContext, useEffect, useState } from 'react';
import { Button, Col, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faSearch } from '@fortawesome/free-solid-svg-icons';
import { IStatResponse } from '../../Model/IStatResponse';
import { IBranch } from '../../Model/IBranch';
import { getAllStats, getAllStatsByManager } from '../../Services/stats';
import { getAllPendReq } from '../../Services/pendingReq';
import LoaderContext from '../../context/loader';
import CardsContain from '../../components/cardContainer/CardsContain';
import CustCard from '../../components/statisticsCard/statCard';
import FilterList from '../../components/filter/filter';
import Star from '../../components/star/star';
import AddModal from '../../components/addModal/addModal';
import './accDah.css';
import { canAdd } from '../../Services/login';

const AccDash: FC = () => {
  const [branch, setSelectedBranch] = useState<IBranch>();
  const [stat, setStat] = useState<IStatResponse[]>([]);
  const [searchWorker, setSearcherWork] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [canAddWorkers, setCanAddWorkers] = useState<boolean>(false);
  const [gettingStats, setGettingStats] = useState(true);
  const [pendingNum, setPending] = useState(0);
  const { loged, setLoged, setPendReqNum } = useContext(LoaderContext);

  useEffect(() => {
    setLoged(localStorage.getItem('logedUser')?.split(',')[3].split(':')[1]);
  }, []);
  useEffect(() => {
    canAdd().then((response) => {
      setCanAddWorkers(response.data.value);
    });
  }, [setCanAddWorkers]);

  useEffect(() => {
    setGettingStats(true);
    if (branch?.id === 'All') {
      getAllStatsByManager()
        .then((response) => {
          setStat(response);
        })
        .catch((e) => {
          setStat([]);
        })
        .finally(() => {
          setGettingStats(false);
        });
    } else {
      branch?.id &&
        getAllStats(branch?.id)
          .then((response) => {
            setStat(response);
          })
          .catch((e) => {
            setStat([]);
          })
          .finally(() => {
            setGettingStats(false);
          });
    }
    getAllPendReq()
      .then((response) => {
        let pendReqLength = 0;
        response.data.forEach((req) => {
          if (req.status === 0) pendReqLength++;
        });
        setPending(pendReqLength);
        setPendReqNum(pendReqLength);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [branch]);

  return (
    <>
      <div className="container-fluid">
        <Row className="d-flex my-3">
          <Col className="col-md-auto">
            <FilterList setBranch={setSelectedBranch} stat={stat} />
          </Col>
          {branch?.id !== 'All' && (
            <>
              {(loged === '"Operations Manager"' || loged === '"Area Manager"') && (
                <Col className="col-md-auto d-md-none d-inline">
                  <Star branchRate={branch?.rating} />
                </Col>
              )}
              <Col className="col-md-auto" xs={12} sm={12}>
                <div className="text-secondary">
                  <p>
                    {branch?.address} #{branch?.code}
                  </p>
                </div>
              </Col>
            </>
          )}
          {branch?.id !== 'All' && (
            <>
              {(loged === '"Operations Manager"' || loged === '"Area Manager"') && (
                <>
                  <Col className="col-md-auto d-none d-sm-inline">
                    <Star branchRate={branch?.rating} />
                  </Col>
                  <Col
                    className={
                      branch?.total_capacity === branch?.total_actual_capacity
                        ? 'text-danger col-md-auto'
                        : 'text-secondary col-md-auto'
                    }>
                    ({branch?.total_capacity} / {branch?.total_actual_capacity})
                  </Col>
                </>
              )}
            </>
          )}
        </Row>

        <Row>
          {gettingStats ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="danger" />
            </div>
          ) : (
            <>
              <Col lg={3} md={4} sm={6} className="mb-3">
                <CustCard title="طلبات معلقة" number={pendingNum} />
              </Col>
              {stat.map((stat) => (
                <Col lg={3} md={4} sm={6} key={stat.id} className="mb-3">
                  <CustCard stat={stat} />
                </Col>
              ))}
            </>
          )}
        </Row>

        <Row>
          <Col xs={12} sm={12} md={2}>
            <InputGroup className="mb-3" dir="ltr">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <FormControl
                type="search"
                aria-label="Search"
                onChange={(e) => setSearcherWork(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col xs={0} sm={0} md={9}></Col>
          <Col xs={12} sm={12} md={1}>
            {canAddWorkers && (
              <Button
                disabled={!canAddWorkers}
                className="addButton mb-2"
                onClick={() => setShowAdd(true)}>
                اضافة
                <FontAwesomeIcon className="me-2" icon={faAdd} />
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <CardsContain searchWorker={searchWorker} selectedBranch={branch?.id} />
        </Row>
      </div>
      <AddModal showAdd={showAdd} setShowAdd={setShowAdd} />
    </>
  );
};

export default AccDash;
