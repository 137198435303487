import { ChangeEvent, FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Button, FloatingLabel, Alert, Spinner } from 'react-bootstrap';
import { canAdd, IUserLogin, logedUser, login } from '../../Services/login';
import cookiesSrvs from '../../Services/cookies';
import LoaderContext from '../../context/loader';
import './lgnForm.css';

const LForm: FC = () => {
  const INITIAL_USER_DATA = {
    username: '',
    password: ''
  };

  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState<IUserLogin>(INITIAL_USER_DATA);
  const [validated, setValidated] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const { setLoged } = useContext(LoaderContext);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoginUser((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event: any) => {
    setRequesting(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    const data = {
      username: loginUser.username,
      password: loginUser.password
    };
    setValidated(true);
    login(data)
      .then((response) => {
        localStorage.setItem('user', response?.config?.data.split('"')[3]);
        cookiesSrvs.setTokens(response.data);
        logedUser()
          .then((response) => {
            localStorage.setItem('logedUser', JSON.stringify(response.data));
            navigate('/dashboard');
          })
          .catch((e) => console.log(e));
        canAdd()
          .then((response) => {
            console.log(response.data.value);
            localStorage.setItem('canAdd', JSON.stringify(response.data.value));
          })
          .catch((e) => console.log(e));
      })
      .catch((error) => {
        toast.error('خطأ فى تسجيل الدخول ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      style={{ direction: 'rtl' }}
      className="d-flex flex-column justify-content-center">
      <FloatingLabel controlId="floatingInput" label="Username" className="mb-3">
        <Form.Control
          dir="ltr"
          type="text"
          placeholder="Username"
          autoComplete="off"
          aria-describedby="inputGroupPrepend"
          name="username"
          value={loginUser.username}
          onChange={handleInputChange}
          required
        />
        <Form.Control.Feedback type="invalid">
          <Alert variant="danger" className="p-1">
            اسم المستخدم مطلوب
          </Alert>
        </Form.Control.Feedback>
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="Password">
        <Form.Control
          type="password"
          placeholder="Password"
          dir="ltr"
          aria-describedby="inputGroupPrepend"
          name="password"
          value={loginUser.password}
          onChange={handleInputChange}
          required
        />
        <Form.Control.Feedback type="invalid">
          <Alert variant="danger" className="p-1">
            الرقم السرى مطلوب
          </Alert>
        </Form.Control.Feedback>
      </FloatingLabel>
      <Button
        type="submit"
        className="loginBtn mt-3 align-self-center w-md-100"
        disabled={requesting}>
        {requesting ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          'Login'
        )}
      </Button>
    </Form>
  );
};

export default LForm;
