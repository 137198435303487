import axios from './axiosInstance';

export interface IPendReqResponse {
  id: number;
  branch: number;
  manager_name: string;
  comment: string;
  type: string;
  notification_text: string;
  status: number;
  created_at: string;
}
export const getAllPendReq = () => {
  return axios.get<Array<IPendReqResponse>>(`api/request/me/`); //api/request/pending/me
};
export const approveRequest = (id: number) => {
  return axios.put(`/api/request/approve/${id}/`);
};
export const disapproveRequest = (id: number) => {
  return axios.put(`/api/request/disapprove/${id}/`);
};
