import { FC, useContext, useEffect, useState } from 'react';
import { Image, Row, Spinner } from 'react-bootstrap';
import UserCard from '../userCard/UserCard';
import { getAll, getWorkerByManager } from '../../Services/worker';
import { IUser } from '../../Model/user';
import { IAccDash } from '../../props';
import noWorkers from '../../assets/images/no-notifications.svg';
import './CardsCont.css';

const CardsContain: FC<IAccDash> = ({ searchWorker, selectedBranch }) => {
  const [workers, setWorkers] = useState<IUser[]>([]);
  const [filteredWorkers, setFilteredWorkers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (selectedBranch === 'All') {
      getWorkerByManager()
        .then((response) => {
          setWorkers(response);
          setFilteredWorkers(response);
        })
        .catch()
        .finally(() => {
          setLoading(false);
        });
    } else {
      selectedBranch &&
        getAll(selectedBranch)
          .then((response) => {
            setWorkers(response);
            setFilteredWorkers(response);
            setLoading(false);
          })
          .catch();
    }
  }, [selectedBranch]);

  useEffect(() => {
    setLoading(true);
    setFilteredWorkers(workers.filter((worker) => worker.name.includes(searchWorker || '')));
    setLoading(false);
  }, [searchWorker]);

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : filteredWorkers.length ? (
        filteredWorkers.map((user, index) => {
          index + 1;
          return <UserCard key={index} usersData={user} selectedBranch={selectedBranch} />;
        })
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <Image src={noWorkers} width={500} />
          </div>
          <div className="textNoWorkers mb-5 mt-2 d-flex justify-content-center">
            لا يوجد موظفين فى هذا الفرع !!
          </div>
        </>
      )}
    </>
  );
};

export default CardsContain;
