import { FC, useContext } from 'react';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Container, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRightFromBracket,
  faBell,
  faClipboardList
} from '@fortawesome/free-solid-svg-icons';
import LoaderContext from '../../context/loader';
import cookiesSrvs from '../../Services/cookies';
import './Nav.css';

const CustomNav: FC = () => {
  const { loged, pendReqNum } = useContext(LoaderContext);
  const loc = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <Navbar className="navbarContainer" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand>
            <img
              src="https://cdn.shopify.com/s/files/1/0616/1298/2512/files/carina_copy_white_360x.png?v=1649059830"
              width="150"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <div className="me-5 my-2 my-lg-0 d-flex">
            {loc.pathname === '/notif' || loc.pathname.includes('/Rating') ? (
              <FontAwesomeIcon
                onClick={() => {
                  navigate('/');
                }}
                className="iconNav ms-3 mt-2 align-self-center"
                icon={faArrowLeft}
              />
            ) : (
              <>
                <FontAwesomeIcon
                  onClick={() => {
                    cookiesSrvs.clearAllTokens();
                    navigate('/');
                  }}
                  className="iconNav ms-3 mt-2 align-self-center"
                  icon={faArrowRightFromBracket}
                />
                <Link to="/notif">
                  {loged == '"Operations Manager"' || loged == '"Area Manager"' ? (
                    <>
                      {pendReqNum !== 0 && (
                        <Badge bg="danger" className="position-absolute me-2">
                          {pendReqNum}
                        </Badge>
                      )}
                      <FontAwesomeIcon
                        className="iconNav ms-3 mt-2 align-self-center"
                        icon={faBell}
                      />
                    </>
                  ) : (
                    <FontAwesomeIcon
                      className="iconNav ms-3 mt-2 align-self-center"
                      icon={faClipboardList}
                    />
                  )}
                </Link>
              </>
            )}
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNav;
