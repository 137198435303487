import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IBranch } from '../../Model/IBranch';
import { IRole } from '../../Model/IRole';
import { getAll } from '../../Services/branch';
import { getAllRoles } from '../../Services/role';
import { transferWorker } from '../../Services/worker';
import { IModal } from '../../props';
import './funcModal.css';

const TransModalContent: FC<IModal> = ({ title, selectedBranch, isShowl, setShowl, usersData }) => {
  const INITIALAT_TRANS_STATE = {
    worker: 0,
    new_branch: '',
    new_role: 0,
    comment: '',
    branch: usersData?.branch
  };

  const [trans, setTrans] = useState(INITIALAT_TRANS_STATE);
  const [allBranches, setAllBranches] = useState<IBranch[]>([]);
  const [allRoles, setAllRoles] = useState<IRole[]>([]);
  const [validate, setValidate] = useState(true);
  const [requesting, setRequesting] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<any>) => {
    const { name, value } = event.target;
    setTrans((values) => ({ ...values, [name]: value }));
    if (name === 'new_role' && event.target.value) {
      setValidate(false);
    }
  };

  const transWorker = () => {
    setRequesting(true);
    const data = {
      worker: Number(usersData?.id),
      comment: trans.comment,
      new_branch: +trans.new_branch,
      new_role: +trans.new_role,
      branch: Number(trans.branch)
    };
    transferWorker(data)
      .then((response) => {
        setTrans({
          worker: usersData?.id || 0,
          comment: response.data.comment,
          new_branch: response.data.new_branch,
          new_role: response.data.new_role,
          branch: response.data.branch
        });
        toast.success(`تم طلب ${title} ل${usersData?.name}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((e: any) => {
        toast.error(`${e.response.data.detail[1]}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  useEffect(() => {
    getAll()
      .then((response) => {
        setAllBranches(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    trans.new_branch &&
      getAllRoles(trans.new_branch)
        .then((response) => {
          setAllRoles(response);
        })
        .catch((error) => {
          setAllRoles([]);
        });
  }, [trans.new_branch]);

  const onClick = () => {
    transWorker();
    setShowl(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-3 me-2 mb-3">
        <div style={{ width: '47%' }}>
          <Form.Select
            name="new_branch"
            value={trans.new_branch}
            onChange={handleInputChange}
            aria-label="branches">
            <option disabled={!!trans.new_branch}>اختر فرع</option>
            {allBranches.map((branch) => {
              return (
                <option value={branch.id} key={branch.id}>
                  {branch.name}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <div style={{ width: '47%' }}>
          <Form.Select
            disabled={!trans.new_branch}
            name="new_role"
            value={trans.new_role}
            onChange={handleInputChange}
            aria-label="roles">
            {allRoles.length ? (
              <option disabled={!!trans.new_role}>اختر وظيفة</option>
            ) : (
              <option disabled>لا يوجد وظائف فى هذا الفرع</option>
            )}
            {allRoles.map((role) => {
              return (
                <option value={role.id} key={role.id}>
                  {role.title}
                </option>
              );
            })}
          </Form.Select>
        </div>
      </div>
      <div className="mt-3 me-2" style={{ width: '97%' }}>
        <FloatingLabel controlId="floatingTextarea2" label="سبب النقل">
          <Form.Control
            name="comment"
            value={trans.comment}
            onChange={handleInputChange}
            as="textarea"
            style={{ height: '100px' }}
          />
        </FloatingLabel>
      </div>
      <Button
        disabled={validate || requesting}
        className="float-start mt-3"
        style={{ width: '20%' }}
        onClick={onClick}>
        {requesting ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <span>تأكيد</span>
        )}
      </Button>
    </>
  );
};

export default TransModalContent;
