import { useCookies } from 'react-cookie';

const cookiesSrvs = (function () {
  function setTokens(token: { access: string; refresh: string }) {
    localStorage.setItem('access_token', token.access);
    localStorage.setItem('refresh_token', token.refresh);
  }

  function getTokens(type: string) {
    if (type === 'access') return localStorage.getItem('access_token') || '';
    else if (type === 'refresh') return localStorage.getItem('refresh_token') || '';
    return '';
  }

  function clearTokens(type: string) {
    if (type === 'access') localStorage.removeItem('access_token');
    else if (type === 'refresh') localStorage.removeItem('refresh_token');
  }

  function clearAllTokens() {
    localStorage.clear();
  }

  return {
    setTokens: setTokens,
    getTokens: getTokens,
    clearTokens: clearTokens,
    clearAllTokens: clearAllTokens
  };
})();

export default cookiesSrvs;
