import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Image, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import QA from '../../components/QA/QA';
import Star from '../../components/star/star';
import { IUser } from '../../Model/user';
import { getWorkerByID } from '../../Services/worker';
import { setAs } from '../../Services/QA';
import placeholder from '../../assets/images/carina-user.png';
import NoQAs from '../../assets/images/no-notifications.svg';
import './RatingPage.css';

const RatingPage: FC = () => {
  const [worker, setWorker] = useState<IUser>();
  const [Qus, setQus] = useState<number[]>();
  const [Ans, setAns] = useState<any>();
  const [requesting, setRequesting] = useState(false);

  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    const workerID = param.id || '';
    getWorkerByID(workerID)
      .then((response) => {
        setWorker(response.data);
      })
      .catch((_) => {
        // console.log(error);
      });
  }, []);

  const sendAnswers = () => {
    setRequesting(true);
    const data = {
      worker: worker?.id,
      branch: worker?.branch,
      questions: Qus,
      answers: Ans == null ? [] : Object.values(Ans),
      comment: ''
    };
    setAs(data)
      .then((_) => {
        navigate(-1);
      })
      .catch((_) => {
        toast.error(`لقد فشل الطلب `, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  return (
    <>
      <Container className="mt-2">
        {Qus?.length ? (
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="text-center mb-2 textTitles"> الصورة </span>
                <Image
                  src={`http://carina-retail.com/${worker?.image}` || placeholder}
                  width={50}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = placeholder;
                  }}
                />
              </div>
              <div className="d-flex flex-column">
                <span className="text-center mb-2 textTitles"> اسم الموظف </span>
                <span style={{ color: '#6C757D', textAlign: 'center' }}>
                  {worker?.first_name} {worker?.second_name}
                </span>
              </div>
              <div className="d-none d-md-flex flex-column">
                <span className="text-center mb-2 textTitles"> نوع الوظيفة </span>
                <span style={{ color: '#6C757D', textAlign: 'center' }}> {worker?.role_name} </span>
              </div>
              <div className="d-none d-md-flex flex-column">
                <span className="text-center mb-2 textTitles"> كود الموظف </span>
                <span style={{ color: '#6C757D', textAlign: 'center' }}>
                  #{worker?.national_id}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="text-center mb-2 textTitles"> تقييم الموظف الحالى </span>
                <Star branchRate={worker?.rating} />
              </div>
            </div>
            <hr className="horizLine mt-4 mb-2" />
          </>
        ) : (
          <>
            <div className="h-100 image-container mt-5">
              <img src={NoQAs} className="mx-auto" width="50%" height="50%" />
            </div>
            <div className="textNoWorkers mb-5 mt-2 d-flex justify-content-center">
              لا يوجد اسئلة تقييم لهذه الوظيفة !!
            </div>
          </>
        )}

        <QA userRole={worker} Qus={setQus} Ans={setAns} />

        {Qus?.length ? (
          <>
            <Button className="btnSendRate float-start" onClick={sendAnswers} disabled={requesting}>
              {requesting ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="danger" />
                </div>
              ) : (
                <>
                  ارسال
                  <FontAwesomeIcon className="me-2" icon={faPaperPlane} />
                </>
              )}
            </Button>
          </>
        ) : (
          <div></div>
        )}
      </Container>
    </>
  );
};

export default RatingPage;
