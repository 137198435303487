import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IRole } from '../../Model/IRole';
import { getAllRoles } from '../../Services/role';
import { changeWorkerRole, traineeUpgradeRequest } from '../../Services/worker';
import { IModal } from '../../props';
import './funcModal.css';

const ChangeModalContent: FC<IModal> = ({ selectedBranch, title, setShowl, usersData }) => {
  const INITIAL_CHANGE_ROLE_STATE = {
    worker: '',
    new_role: '',
    comment: '',
    branch: usersData?.branch,
    code: ''
  };

  const [allRoles, setAllRoles] = useState<IRole[]>([]);
  const [validate, setValidate] = useState(true);
  const [newRole, setNewRole] = useState(INITIAL_CHANGE_ROLE_STATE);
  const [requesting, setRequesting] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<any>) => {
    const { name, value } = event.target;
    setNewRole((values) => ({ ...values, [name]: value }));
    if (usersData?.is_trainee) {
      if (newRole.new_role && newRole.code) setValidate(false);
    } else {
      if (value) setValidate(false);
    }
  };

  const changeRole = () => {
    setRequesting(true);
    const data = {
      worker: usersData?.id,
      comment: newRole.comment,
      new_role: newRole.new_role,
      branch: newRole.branch,
      code: newRole.code
    };
    if (usersData?.is_trainee) {
      traineeUpgradeRequest(data)
        .then((response) => {
          setNewRole({
            worker: `${usersData?.id}`,
            comment: response.data.comment,
            new_role: response.data.new_role,
            branch: response.data.branch,
            code: response.data.internal_code
          });
          toast.success(`تم طلب ${title} ل${usersData?.name}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((e: any) => {
          toast.error(`${e.response.data.detail[1]}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        })
        .finally(() => {
          setRequesting(false);
        });
    } else {
      changeWorkerRole(data)
        .then((response) => {
          setNewRole({
            worker: `${usersData?.id}`,
            comment: response.data.comment,
            new_role: response.data.new_role,
            branch: response.data.branch,
            code: response.data.internal_code
          });
          toast.success(`تم طلب ${title} ل${usersData?.name}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((e: any) => {
          toast.error(`${e.response.data.detail[1]}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        })
        .finally(() => {
          setRequesting(false);
        });
    }
  };

  useEffect(() => {
    usersData?.branch &&
      getAllRoles(usersData?.branch)
        .then((response) => {
          setAllRoles(response);
        })
        .catch((error) => {
          setAllRoles([]);
        });
  }, []);

  const onClick = () => {
    changeRole();
    setShowl(false);
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-3 me-2">
        <div style={{ width: '45%' }}>
          <Form.Select
            name="new_role"
            value={newRole.new_role}
            onChange={handleInputChange}
            aria-label="roles">
            {allRoles.length ? (
              <option disabled={!!newRole.new_role}>اختر وظيفة</option>
            ) : (
              <option disabled>لا يوجد وظائف فى هذا الفرع</option>
            )}
            {allRoles.map((role) => {
              return (
                <option value={role.id} key={role.id}>
                  {role.title}
                </option>
              );
            })}
          </Form.Select>
        </div>
        {usersData?.is_trainee && (
          <div style={{ width: '52%' }}>
            <Form.Control
              type="text"
              name="code"
              placeholder="كود الموظف"
              value={newRole.code}
              onChange={handleInputChange}
            />
          </div>
        )}
      </div>
      <div className="mt-2 me-2" style={{ width: '97%' }}>
        <FloatingLabel controlId="floatingTextarea2" label="سبب تغيير الوظيفة">
          <Form.Control
            name="comment"
            value={newRole.comment}
            onChange={handleInputChange}
            as="textarea"
            style={{ height: '100px' }}
          />
        </FloatingLabel>
      </div>
      <Button
        disabled={validate || requesting}
        className="float-start mt-3"
        style={{ width: '20%' }}
        onClick={onClick}>
        {requesting ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <span>تأكيد</span>
        )}
      </Button>
    </>
  );
};

export default ChangeModalContent;
