import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUserCard } from '../../props';
import 'react-toastify/dist/ReactToastify.css';
import './editTypes.css';

const EditTypes: FC<IUserCard> = ({ setTitle, text, setShow, setShowl, usersData }) => {
  return (
    <>
      <Button
        className="p-3 m-2 text-white rounded-3 typesDiv"
        style={{ backgroundColor: text?.color }}
        disabled={text?.title === 'اخلاء طرف' && usersData.resign_date === null}
        onClick={() => {
          setShow(false);
          setShowl(true);
          setTitle(text?.title);
        }}>
        <div className="text-center d-flex flex-column">
          <FontAwesomeIcon className="mb-3 typesicon" icon={text?.icon} />
          {text?.title === 'طلب استقالة' && usersData.resign_date ? (
            <span className="typesTitle"> الغاء الاستقالة </span>
          ) : (
            <span className="typesTitle"> {text?.title} </span>
          )}
        </div>
      </Button>
    </>
  );
};

export default EditTypes;
