import { FC } from 'react';
import LForm from '../../components/loginForm/lgnForm';
import './Login.css';

const Login: FC = () => {
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center loginPage">
        <img
          className="login-landing"
          src="https://cdn.shopify.com/s/files/1/0616/1298/2512/files/carina_copy_white_360x.png?v=1649059830"
        />
        <span className="text-white ms-4"> Retail services department </span>
        <div className="formDiv">
          <LForm />
        </div>
      </div>
    </>
  );
};

export default Login;
