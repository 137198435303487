import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faStar, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { IUserCard } from '../../props';
import { confirmTransWorker } from '../../Services/worker';
import EditModal from '../editModal/EditModal';
import Star from '../star/star';
import placeholder from '../../assets/images/carina-user.png';
import ReactTimeAgo from 'react-time-ago';
import './userCard.css';

const UserCard: FC<IUserCard> = ({ usersData, selectedBranch }) => {
  const [show, setShow] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const navigate = useNavigate();

  const handleShow = () => setShow(true);

  const deliverWorker = () => {
    setRequesting(true);
    confirmTransWorker(usersData?.id)
      .then((response) => {
        toast.success(`تم استلام ${usersData.name}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        window.location.reload();
      })
      .catch((e) => {
        toast.error(`لقد فشل الطلب `, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const navigateOrNot = (usersData: any) => {
    if (!usersData.can_be_rated) return;
    else navigate(`/Rating/${usersData.id}`);
  };

  return (
    <>
      <Col md={2} sm={12} className="userCardCol">
        <Card className="userCard">
          <Card.Img
            variant="top"
            className="userImgC"
            src={`http://carina-retail.com/${usersData?.image}` || placeholder}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = placeholder;
            }}
          />
          <Card.Body style={{ textAlign: 'center' }}>
            <Card.Title className="cardTitleName">{usersData?.name}</Card.Title>
            <Card.Text>{usersData?.role_name}</Card.Text>
            <Card.Text>#{usersData?.code}</Card.Text>
            <Card.Text>
              <b>وقت منذ التعيين : </b>
              <ReactTimeAgo date={Date.parse(usersData.hiring_date)} locale="ar" />
            </Card.Text>
            <Star branchRate={usersData?.rating} />
          </Card.Body>
          <Card.Footer className="d-flex justify-content-around">
            {usersData.is_in_branch ? (
              <>
                {usersData.can_be_rated && (
                  <>
                    <button
                      onClick={() => navigateOrNot(usersData)}
                      className="btn rateBtn"
                      id="Rate">
                      <FontAwesomeIcon icon={faStar} />
                      <span className="me-2">تقييم</span>
                    </button>
                    <div className="vr"></div>
                  </>
                )}
                <button
                  disabled={usersData.has_pending_request}
                  className="btn btn-secondary text-white editBtn"
                  id="Edit"
                  onClick={handleShow}>
                  <FontAwesomeIcon icon={faUserCog} />
                  <span className="me-2">تعديل</span>
                </button>
              </>
            ) : (
              <button
                className="btn btn-success text-white editBtn w-75"
                disabled={requesting}
                id="Edit"
                onClick={deliverWorker}>
                {requesting ? (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="danger" />
                  </div>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCheckDouble} />
                    <span className="me-2">استلام</span>
                  </>
                )}
              </button>
            )}
          </Card.Footer>
        </Card>
      </Col>

      <EditModal
        isShow={show}
        setShow={setShow}
        usersData={usersData}
        selectedBranch={selectedBranch}
      />
    </>
  );
};

export default UserCard;
