import { IValidate } from '../../Model/Ivalidation';

const INITIAL_WORKER_STATE = {
  first_name: '',
  second_name: '',
  third_name: '',
  fourth_name: '',
  code: '',
  national_id: '',
  phone_number: '',
  gender: '',
  birth_date: '',
  hiring_date: '',
  is_graduated: '',
  comment: '',
  role: '',
  branch: '',
  // email: '',
  image: '',
  location: ''
  // is_trainee: true,
};

const NUM_EN_PATTERN = /^[0-9]{11}$/;

const ID_EN_PATTERN = /^[0-9]{14}$/;

const MSG = 'رقم الهاتف يجب ان يكون 11 رقم';

const MSG2 = 'الرقم القومى يجب ان يكون 14 رقم';

const NAMES: IValidate = {
  first_name: { validate: (str: string) => str.length <= 0, msg: 'الاسم الاول مطلوب' },
  second_name: { validate: (str: string) => str.length <= 0, msg: 'الاسم الثانى مطلوب' },
  third_name: { validate: (str: string) => str.length <= 0, msg: 'الاسم الثالث مطلوب' },
  fourth_name: { validate: (str: string) => str.length <= 0, msg: 'الاسم الرابع مطلوب' },
  birth_date: { validate: (str: string) => str.length <= 0, msg: 'تاريخ الميلاد مطلوب' },
  hiring_date: { validate: (str: string) => str.length <= 0, msg: 'تاريخ التوظيف مطلوب' },
  role: { validate: (str: string) => str.length <= 0, msg: 'الوظيفة مطلوبة' },
  branch: { validate: (str: string) => str.length <= 0, msg: 'الفرع مطلوب' },
  location: { validate: (str: string) => str.length <= 0, msg: 'مكان السكن مطلوب' },
  is_graduated: { validate: (str: string) => str.length <= 0, msg: 'الدرجة علمية مطلوبة' },
  code: { validate: (str: string) => str.length <= 0, msg: 'كود الموظف مطلوب' },
  gender: { validate: (str: string) => str === '', msg: 'جنس الموظف مطلوب' },
  phone_number: { validate: (str: string) => !NUM_EN_PATTERN.test(str), msg: MSG },
  national_id: { validate: (str: string) => !ID_EN_PATTERN.test(str), msg: MSG2 }
};

export { INITIAL_WORKER_STATE, NUM_EN_PATTERN, ID_EN_PATTERN, MSG, MSG2, NAMES };
