import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const calculateStars = (branchRate: number) => {
  let count = 0;
  const starsArr = [];
  for (let rate = 0; rate < Math.floor(branchRate); rate++) {
    count++;
    starsArr.push(
      <FontAwesomeIcon
        style={{ color: 'gold', fontSize: '12px' }}
        icon={faStar}
        className="flex-fill"></FontAwesomeIcon>
    );
  }
  for (let i = count; i < 5; i++) {
    starsArr.push(
      <FontAwesomeIcon
        style={{ color: 'gray', fontSize: '12px' }}
        icon={faStar}
        className="flex-fill"></FontAwesomeIcon>
    );
  }
  return starsArr;
};

export { calculateStars };
